import './landing.css'
import Footer from './footer';
function Terms() {
    return (
        <div>
<div class="container pt-36">
            <h1 style={{lineHeight: '1.3'}} className='text-3xl text-[#2F373D] inter-font font-normal'>
                Terms & Conditions
            </h1>
            <p className='text-[#2F373D] text-sm mt-4 roboto-font font-bold opacity-70'>
                RubixTeacher   End User License Agreement
            </p>
            <p className='text-[#2F373D] mt-1 roboto-font text-sm font-normal opacity-70'>
                Effective date: January 2019
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Welcome to <span className='font-bold'>RubixTeacher!</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                IMPORTANT: PLEASE READ THE TERMS AND CONDITIONS OF THIS LICENSE AGREEMENT CAREFULLY BEFORE CONTINUING WITH USING THIS APPLICATION
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                The following Terms are a legally binding contract <span className='font-bold'>between you and Rubix 108 Technologies Pvt Ltd. </span> 
                (“Rubix 108”, “we”, or “us”). 
                <span className='font-bold'>These Terms govern and apply to your access to RubixTeacher</span>(“App”) <span className='font-bold'>mobile application. </span>
                By accessing or using our App, you agree to be bound to all of the terms and conditions described in this EULA(End User License Agreement), including the Rubix 108 <a className='primary-color' href="/privacy-policy">Privacy Policy.</a> If you do not agree to all of these terms and conditions, do not use our Service.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                If you are entering into this Agreement on behalf of an educational Institute, you represent that you have the authority to bind such Institute to these terms and conditions, in which case the terms “you”, “your” or “Member” shall refer to such institute. If you do not have such authority, or if you do not agree with these terms and conditions, you must not accept this Agreement and may not use the Service.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                We reserve the right, at our discretion, to change this EULA at any time. If it do change, we will do our best to tell you in advance by sending you an email. If you use the Application in any way after a change to the Terms is effective, then please remember that means you agree to all of the Terms. If you have any questions, comments, or concerns regarding this, please contact us at <a className='primary-color' href = "mailto: support@rubix108.com">support@rubix108.com</a>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                1. <span className='font-bold'>Eligibility</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                You must be at least eighteen (18) years old to use the Application. By agreeing to the Terms, you represent and warrant to us that you are at least eighteen (18) years old and, that your registration and your use of the Service is in compliance with any and all applicable laws and regulations.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                2. <span className='font-bold'>Privacy Policy</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Please read the Rubix 108 Privacy Policy carefully for information regarding our collection, use, and disclosure of your personal information. If any conflict exists between the EULA and our <a className='primary-color' href="/privacy-policy">Privacy Policy.</a>, the EULA will prevail.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                3. <span className='font-bold'>Accounts and Registration</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                You are solely responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account. You may not transfer your account to anyone else without our prior written permission.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Rubix Teacher collects your data that may be provided by you or by your work institute. The data required by the app is your first name, last name, email id(optional), location detection(non-continuous), social media accounts(specifically with permission) and phone number(registered with the institute).
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                Certain specified data is mandatory to be provided otherwise, it may not be possible for the app to function properly. The user’s personal data stays with the system for as long as it is required for the purpose intended. Users who are concerned about the data required mandatorily can easily contact on the app helpline number.
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                Rubix Teacher may ask for some permissions depending upon the device being used for the app. These permissions are to be granted by default, to access different features in the app. The user has the right to withdraw these permissions anytime by changing the device’s settings. Please note that withdrawing such permissions might affect the proper functioning of the Rubix Teacher app.
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                Rubix Teacher may ask for Facebook permissions in order to complete action by using User’s Facebook account. In such a case, the data from User’s Facebook account will be collected such as id, name, picture and location. The data available to be viewed for the public on User’s Facebook account will be available for Rubix Teacher.
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                Rubix Teacher might lead you to some YouTube videos related to the in-app content. 
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                Rubix Teacher checks for the location of the User in order to provide services that are based on location. Location detection of the User is a non-continuous process. It clearly means that Rubix Teacher will not detect the User’s location in a continuous manner. 
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                4. <span className='font-bold'>Intellectual Property Rights</span>
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>4.1 Content</span>
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                The materials displayed or performed on the App, (including, but not limited to, text, graphics, articles, photos, images, illustrations, User Submissions (defined below), and so forth (the “Content”) and the trademarks, service marks and logos contained on the App (“Marks”), are protected by copyright and other intellectual property laws. Content is provided for your information and personal use only and may not be used, copied, distributed, transmitted, displayed, sold, licensed, reverse engineered, de-compiled, or otherwise exploited for any other purposes whatsoever without prior written consent of the owner of the Content or in any way that violates someone else’s rights, including Rubix 108.
            </p>
            <p className='text-[#2F373D] mt-10 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>4.2 User Submissions</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Anything you post, upload, share, store, or otherwise provide through the Service is your “User Submission.” You retain copyright and any other proprietary rights you hold in the User Submission that you post to the App. For all User Submissions, you hereby grant Rubix108 a worldwide, non-exclusive, transferable, assignable, fully paid-up, royalty-free right and license to host, transfer, display, perform, reproduce, modify, distribute and re-distribute, and otherwise exploit your User Submissions, in whole or in part, in any media formats and through any media channels .This is a license only – your ownership in User Submissions is not affected.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                By posting and sharing User Submissions with another user of the Service, you hereby grant that user a non-exclusive license to access and use such User Submissions.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                You are solely responsible for your User Submissions and the consequences of posting or publishing User Submissions. By posting and publishing User Submissions, you affirm, represent, and warrant that:
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                All the data that is being shared by the Teacher, like homework, assignments, notices, consent letter, whatsoever communication it may be; Teacher is the only person responsible for the communication being shared.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                You are the creator and owner of, or have the necessary licenses, rights, consents, and permissions to use and to authorize Rubix108 and users of the Service to use and distribute your User Submissions as necessary to exercise the licenses granted by you in this Section 4 and in the manner contemplated by Rubix108 and the Terms;
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                We are under no obligation to edit or control User Submissions that you and other users post or publish, and will not be in any way responsible or liable for User Submissions..
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>5. Prohibited Conduct</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                BY USING THE SERVICE YOU AGREE NOT TO:
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Publish the private information of any third party without the consent of that third party;
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Post, upload, or distribute any User Submission or other Content that is unlawful or that a reasonable person could deem to be objectionable, profane, offensive, indecent, pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially or ethnically offensive, or otherwise inappropriate;
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Make unsolicited offers, advertisements, proposals, or send junk mail or spam to other users of the Service. 
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Intentionally interfere with or damage operation of the Service or any user’s enjoyment of it, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>6. App Usage</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                We make available App subject to the restrictions defined in these Terms, Rubix108 grants you a limited, non-exclusive, non-transferable, non-sub licensable, revocable license to install and use one copy of an App in object code format, solely for your personal use, on one device that you own or control.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                An App may be made available to you through a third-party distributor such as the Apple iTunes App Store or Google Play (each an <span className='font-bold'>“App Distributor”</span>). You acknowledge and agree that:
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                The Terms are between you and Rubix108 only, not with the App Distributor and the App Distributor is not responsible for the App and its content.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>7. Third Party Services</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                During the use of RubixTeacher,you may enter into correspondence with,anythir party selling its goods and services.Any such activity,any terms,conditions,warranties or representations associated with such activity are solely between you and applicable third party. 
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>8. Termination of Use</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Rubix108 is free to terminate (or suspend access to) your use of the App or your account, for any reason, including your breach of these Terms. Rubix108 has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms. 
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>9. Indemnity</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                You agree that you will be personally responsible for your use of the Service and you agree to defend, indemnify and hold harmless Rubix 108 its affiliates, and their respective officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs and expenses (including but not limited to attorney’s fees) arising from: (i) your use of the Service; (ii) your violation of these Terms; (iii) your violation of any third party right, including without limitation any copyright, property, publicity or privacy right; or (iv) any claim that one of your User Submissions caused damage to a third party. This defense and indemnification obligation will survive this EULA.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>10. Warranty Disclaimer</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                Rubix 108 Technologies Pvt Ltd expressly disclaims any warranty for the  APPLICATION. The APPLICATION is provided “As Is” without any express or implied warranty of any kind, including but not limited to any warranties of merchantability, non infringement, of a particular purpose. Rubix 108 Technolgies Pvt Ltd does not warrant or assume responsibility for the accuracy or completeness of any information, text, graphics, links or other items contained within the SOFTWARE APPLICATION. Rubix 108 Technolgies Pvt Ltd makes no warranties respecting any harm that may be caused by the transmission of a computer virus, worm, time bomb, logic bomb, or other such computer program. Rubix 108 Technolgies Pvt Ltd further expressly disclaims any warranty or representation to Authorized Users or to any third party. 
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>11. Limitation of Liability</span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                IN NO EVENT SHALL RUBIX 108 TECHNOLGIES PVT LTD BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, BUSINESS INTERRUPTION, OR LOST INFORMATION) RISING OUT OF AUTHORIZED USERS’ USE OF OR INABILITY TO USE THE  APPLICATION, EVEN IF RUBIX 108 TECHNOLGIES PVT LTD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL RUBIX 108 TECHNOLGIES PVT LTD BE LIABLE FOR LOSS OF DATA OR FOR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL (INCLUDING LOST PROFIT), OR OTHER DAMAGES BASED IN CONTRACT, TORT OR OTHERWISE. RUBIX 108 TECHNOLGIES PVT LTD SHALL HAVE NO LIABILITY WITH RESPECT TO THE CONTENT OF THE APPLICATION OR ANY PART THEREOF, INCLUDING BUT NOT LIMITED TO ERRORS OR OMISSIONS CONTAINED THEREIN, LIBEL, INFRINGEMENTS OF RIGHTS OF PUBLICITY, PRIVACY, TRADEMARK RIGHTS, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF PRIVACY, MORAL RIGHTS OR THE DISCLOSURE OF CONFIDENTIAL INFORMATION. 
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>12. Applicable Law </span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                These Terms and your use of the Service shall be governed by and construed in accordance with the laws of India.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <span className='font-bold'>13. Contact Information </span>
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                If you have any questions regarding RubixTeacher, the Terms please contact us at <a className='primary-color' href = "mailto: support@rubix108.com">support@rubix108.com</a>.
            </p>
            <p className='text-[#2F373D] mt-4 roboto-font text-sm font-normal opacity-70'>
                <a className='primary-color' target='_blank' href = "http://rubix108.com/">rubix108.com</a>
            </p>
            <p className='text-[#2F373D] mt-2 roboto-font text-sm font-normal opacity-70'>
                <a className='primary-color' target='_blank' href = "https://www.rubixpower.com:8443/RubixTotal/home/view_home_form">rubixpower.com</a>
            </p>
            <p className='text-[#2F373D] mt-2 roboto-font text-sm font-normal opacity-70'>
                <a className='primary-color' target='_blank' href = "https://www.rtotal.com/">rtotal.com</a>
            </p>
        </div>
            <Footer />
        </div>
        
    );
  }
  
export default Terms;