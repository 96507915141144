import './App.css'
import Pricing from './pages/pricing'
import About from './pages/about'
import Contact from './pages/contact'
import Landing from './pages/landing'
import Collab from './pages/collab'
import Terms from './pages/terms'
import Privacy from './pages/Privacy'
import logo from './pages/logo.png'
import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'

function App() {
    const [bgActive, setBgActive] = useState(false);
    const changeBackground = ()=>{
      if(window.scrollY >= 90){
        setBgActive(true)
      }else{
        setBgActive(false)
      }
    }
    window.addEventListener('scroll', changeBackground)
    return (
      <BrowserRouter>
      <nav className={`navbar md:hidden block fixed-top bg-body-tertiary m-0`}>
        <div className="container-fluid">
          <Link to='/' className="navbar-brand">
            <img className='md:w-40 w-32 md:ml-12 ml-2 mt-2' src={logo} alt="" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end w-full px-8" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <img className='w-40' src={logo} alt="" />
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fa-solid fa-xmark text-xl"></i>
              </button>
            </div>
            <div className="offcanvas-body m-0 w-screen">
              <ul className="navbar-nav justify-content-end m-0 flex-grow-1">
                <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                    <Link to='/' className="col-span-1 secondary-color hover:text-[#169EDA] font-semibold">Home</Link>
                </li>
                <li className="nav-item mt-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                  <Link to='/collab' className="col-span-1 secondary-color hover:text-[#169EDA] font-semibold">Let's Collab</Link>
                </li>
                <li className="nav-item mt-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                  <Link to='/pricing' className="col-span-1 secondary-color hover:text-[#169EDA] font-semibold">Pricing</Link>
                </li>
                <li className="nav-item mt-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                  <Link to='/about' className="col-span-1 secondary-color hover:text-[#169EDA] font-semibold">About Us</Link>
                </li>
                <li className="nav-item mt-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                  <Link to='/contact' className="col-span-1 secondary-color hover:text-[#169EDA] font-semibold">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
        <div className={`grid grid-cols-2 pr-24 z-50 justify-between items-center ease-in duration-300 hidden md:inline-flex ${bgActive ? 'fixed bg-[#2F373D] w-full' : 'absolute w-full'}`}>
            <div className="col-span-1">
              <Link className='w-40' to='/'>
                <img className={`ml-12 mt-2 ease-in duration-300 ${bgActive ? 'h-[70px] w-[100px]' : 'h-auto w-40'}`} src={logo} alt="" />
              </Link>  
            </div>
            <div className="col-span-1">
                <div className={`grid grid-cols-5 items-center text-center gap-12`}>
                    <Link to='/' className={`col-span-1 hover:text-[#169EDA] font-semibold ${bgActive ? 'text-gray-400' : 'secondary-color'}`}>Home</Link>
                    <Link to='/collab' className={`col-span-1 hover:text-[#169EDA] font-semibold ${bgActive ? 'text-gray-400' : 'secondary-color'}`}>Let's Collab</Link>
                    <Link to='/pricing' className={`col-span-1 hover:text-[#169EDA] font-semibold ${bgActive ? 'text-gray-400' : 'secondary-color'}`}>Pricing</Link>
                    <Link to='/about' className={`col-span-1 hover:text-[#169EDA] font-semibold ${bgActive ? 'text-gray-400' : 'secondary-color'}`}>About Us</Link>
                    <Link to='/contact' className={`col-span-1 hover:text-[#169EDA] font-semibold ${bgActive ? 'text-gray-400' : 'secondary-color'}`}>Contact Us</Link>
                </div>
            </div>
        </div>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/collab' element={<Collab />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/terms-conditions' element={<Terms />} />
          <Route path='/privacy-policy' element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    );
  }
  
export default App;