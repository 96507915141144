import './landing.css'
import doodle from './doodle.png'
import Footer from './footer';
import Screen2 from './doodlebg.png'
import Team from './team.jpeg'
import doodlebg from './doodlebg.png'
function Landing() {
    return (
      <div>
        <div style={{backgroundImage: `url(${doodle})`}} className="App">
          <div className='pt-28 pb-16'>
            <div style={{backgroundImage: `url(${doodlebg})`}} className='text-center py-20'>
                <h1 style={{fontFamily: 'Sedgwick Ave, cursive'}} className='md:text-6xl text-4xl text-white inter-font font-bold'>
                  Contact Us
                </h1>
            </div>
            <div className="lg:grid lg:grid-cols-2 flex flex-col-reverse text-center justify-center items-center mt-12 md:mx-20 mx-6">
              <div className='md:col-span-1 md:text-left col-span-2 md:ml-20'>
                <h1 className='text-4xl mt-8 text-[#2F373D] inter-font font-bold'>
                  Head Office
                </h1>
                <p className='text-[#2F373D] mt-2 text-xl roboto-font font-bold opacity-80'>
                  Administrative/Development Center
                </p>
                <p className='text-[#2F373D] mt-3 text-xl roboto-font font-base opacity-80'>
                  4th Floor, Adinath Shopping Center,
                </p>
                <p className='text-[#2F373D] mt-1 text-xl roboto-font font-base opacity-80'>
                  Satara Rd, Market Yard,
                </p>
                <p className='text-[#2F373D] mt-1 text-xl roboto-font font-base opacity-80'>
                  Pune, Maharashtra, India 411037
                </p>
              </div>
              <div className="md:col-span-1 col-span-2">
                <img className='rounded-xl w-[500px]' src={Team} />
              </div>
            </div>
            <div className='mt-20'>
                <p className='text-[#2F373D] mt-2 md:text-5xl text-4xl leading-10 text-center roboto-font font-base opacity-80'>
                  Sales/Support Locations
                </p>
                <div className="flex md:flex-row flex-col mt-6 gap-8 md:mx-20 mx-6 justify-center text-center">
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Pune
                    </h1>
                  </div>
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Mumbai
                    </h1>
                  </div>
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Delhi
                    </h1>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-8 md:mx-20 mx-6 justify-center text-center">
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Banglore
                    </h1>
                  </div>
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Nagpur
                    </h1>
                  </div>
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Patna
                    </h1>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-8 md:mx-20 mx-6 justify-center text-center">
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Bhatinda
                    </h1>
                  </div>
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Ahmedabad
                    </h1>
                  </div>
                  <div className='w-[300px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 py-5 pl-6 flex flex-col'>
                    <h1 className='text-2xl text-[#2F373D] inter-font font-bold'>
                    Indore
                    </h1>
                  </div>
                </div>
            </div>
          </div>
          
          {/* <div className='pt-28 mx-28 grid grid-cols-2 justify-items-stretch items-center'>
              <div className='col-span-1 justify-self-start mr-12'>
                  <img src={contact} className='w-[700px]' alt="" />
              </div>
              <div className='col-span-1 justify-self-end rounded-2xl px-12 ml-12' style={{backgroundImage: `url(${doodlebg})`}}>
                <h1 className='text-3xl pt-8 pr-40 text-white inter-font font-bold'>
                  Contact Us
                </h1>
                <form 
                    className='roboto-font mt-4'
                    id="contactform" 
                    action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                >
                    <input name="_redirect" type="hidden" id="redirect" value="https://www.rtotal.com/?q=true" />
                    <input name="Form-Type" type="hidden" id="type" value="Contact form" />
                    <div class="input-group mb-2">
                      <select class="form-select text-sm" id="inputGroupSelect01">
                        <option selected>Choose Query...</option>
                        <option value="Schools/Colleges">Schools/Colleges</option>
                        <option value="Coaching Institutes">Coaching Institutes</option>
                        <option value="Freelancing Teachers">Freelancing Teachers</option>
                        <option value="Service Provider">Service Provider</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div class="mb-2">
                        <label for="name" class="form-label mb-1 text-white text-sm opacity-70">Name</label>
                        <input type="text" name='Name' class="form-control text-sm" id="name" placeholder='Your name' />
                    </div>
                    <div class="mb-2">
                        <label for="tel" class="form-label mb-1 text-white text-sm opacity-70">Mobile</label>
                        <input type="tel" maxLength={10} minLength={10} name='Mobile' class="form-control text-sm" id="tel" placeholder='Your mobile no.' />
                    </div>
                    <div class="mb-2">
                        <label for="email" class="form-label mb-1 text-white text-sm opacity-70">Email address</label>
                        <input type="email" name='Email' class="form-control text-sm" id="email" placeholder='Your email' />
                    </div>
                    <div class="mb-3">
                        <label for="query" class="form-label mb-1 text-white text-sm opacity-70">Query</label>
                        <textarea style={{resize: 'none'}} rows={5} type="text" name='Query' placeholder='Enter your query here...' class="form-control text-sm" id="query" />
                    </div>
                    <div className="flex gap-x-5 my-4 justify-end">
                        <button type="submit" class="btn form-control text-white bg-[#2F373D] hover:bg-[#2f373d80] rounded">Send</button>
                    </div>
                </form>
              </div>
          </div> */}
        </div>
        <div style={{backgroundImage: `url(${Screen2})`}} className='py-16 md:mx-32 mx-6 rounded-xl text-center'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl text-center secondary-color inter-font font-bold'>
                  Would you like a personal demo?
                </h1>
                <p className='text-white mt-3 px-6 md:px-0 text-sm roboto-font font-normal'>
                  Our product experts will help you understand how RTotal can help your business through a one-on-one demo session
                </p>
                <button data-bs-toggle="modal" data-bs-target="#demo" type="button" class="btn text-white bg-[#2F373D] px-8 mt-8 hover:bg-[#2f373d7a] rounded">Request a demo</button>
                <div class="modal fade" id="demo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="demoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-black fs-5" id="demoLabel">Let's Connect</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <i class="fa-solid fa-xmark text-xl"></i>
                                </button>
                            </div>
                            <div class="modal-body text-left">
                                <form className='px-4 roboto-font text-base' 
                                  action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST">
                                    <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                    <input name="Form-Type" type="hidden" id="name" value="Request a demo" />
                                    <div class="mb-2">
                                        <label for="name" class="form-label mb-1 text-black">Name</label>
                                        <input type="text" name='Name' class="form-control" id="name" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                        <input type="text" name='School-Name' class="form-control" id="sname" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                        <input type="tel" name='Mobile' class="form-control" id="tel" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1 text-black">Email address</label>
                                        <input type="email" name='Email' class="form-control" id="email" required />
                                    </div>
                                    <div className="flex gap-x-5 mt-4">
                                        <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footer />
      </div>
    );
  }
  
export default Landing;