import './landing.css'
import { Link } from 'react-router-dom'
import logo from './logo.png'
function Footer() {
    return (
      <div className='bg-[#2F373D]'>
        <div className='grid grid-cols-4 justify-stretch mt-4 border-b-2 border-black/20 mb-7 pb-7 md:px-16 px-6 pt-6'>
            <div className='md:col-span-1 col-span-4'>
                <img className='w-48' src={logo} alt="" />
                <div className='flex text-[#8A868C] gap-3 justify-start'>
                    <i class="fa-brands fa-facebook-f text-2xl hover:text-white cursor-pointer"></i>
                    <i class="fa-brands fa-twitter text-2xl hover:text-white cursor-pointer"></i>
                    <i class="fa-brands fa-linkedin-in text-2xl hover:text-white cursor-pointer"></i>
                    <i class="fa-brands fa-instagram text-2xl hover:text-white cursor-pointer"></i>
                </div>
            </div>
            <div className="md:col-span-1 col-span-4 justify-self-start pt-7">
              <h1 className='text-xl text-[#8A868C] inter-font font-bold'>
                Services
              </h1>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Email Marketing
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Camping
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Branding
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Offline
              </p>
            </div>
            <div className="md:col-span-1 col-span-4 md:justify-self-center pt-7">
              <h1 className='text-xl text-[#8A868C] inter-font font-bold'>
                About
              </h1>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Our story
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Benifits
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Team
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 hover:text-white cursor-pointer'>
                Careers
              </p>
            </div>
            <div className="md:col-span-1 col-span-4 md:justify-self-end pt-7">
              <h1 className='text-xl text-[#8A868C] inter-font font-bold md:ml-3'>
                Let’s get in touch.
              </h1>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-3 ml-3'>
                <i class="fa-solid fa-phone text-base"></i>
                <a href='https://api.whatsapp.com/send?phone=917219108108' target='_blank' className='pl-2 text-sm'>+91 7219108108</a>
              </p>
              <p className='text-[#8A868C] text-xs roboto-font font-normal mt-2 ml-3'>
                <i class="fa-solid fa-envelope text-base"></i>
                <a href='mailto:support@rubix108.com' target='_blank' className='pl-2 text-sm'>support@rubix108.com</a>
              </p>
              <div className='w-48 mt-6'>
                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.testapp.android.activity&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
              </div>
            </div>
        </div>
        <p style={{lineHeight: '1.7'}} className='text-[#8A868C] mx-6 pl-3 text-center text-xs roboto-font font-normal pb-7'>
          Copyright © 2023 Rubix108 Technologies Private Limited. All right reserved <span className='pl-3'>|</span>  
          <Link to='/terms-conditions' className=" px-3">Terms & Conditions</Link> |
          <Link to='/privacy-policy' className="pl-3">Privacy Policy</Link>
        </p>
      </div>
    );
  }
  
export default Footer;

