import './landing.css'
import doodle from './doodle.png'
import Footer from './footer';
import youstory from './youstory.png'
import Screen2 from './doodlebg.png'
import Business from './business.png'
import Fr from './fr.png'
import Cr from './28.png'
import Career from './career.png'
import Digital from './digital.png'
import Other from './15other.png'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
function Collab() {
    return (
      <div>
        <div style={{backgroundImage: `url(${doodle})`}} className="App">
        <div class="modal fade" id="test1" tabindex="-1" aria-labelledby="test1Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="test1Label">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Lets Collab Form" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test2" tabindex="-1" aria-labelledby="test2Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="test2Label">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Lets Collab Form" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test3" tabindex="-1" aria-labelledby="test3Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="test3Label">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Lets Collab Form" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test4" tabindex="-1" aria-labelledby="test4Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="test4Label">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Lets Collab Form" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test5" tabindex="-1" aria-labelledby="test5Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="test5Label">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Lets Collab Form" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test6" tabindex="-1" aria-labelledby="test6Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="test6Label">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Lets Collab Form" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
          <div className='md:pt-28 pt-40 md:mx-20 mx-6'>
              <h1 style={{lineHeight: '1.3'}} className='md:text-4xl text-3xl text-center secondary-color inter-font font-bold'>
                  Let's Collab
              </h1>
              <div className='grid grid-cols-3 gap-16 py-16'>
                <div data-bs-toggle="modal" data-bs-target="#test1" className='md:col-span-1 col-span-3 bg-white shadow rounded-xl hover:scale-105 transition-all cursor-pointer'>
                    <div className='text-center px-3 pt-10'>
                        <div className='flex justify-center'>
                            <img className='w-[150px] rounded-xl' src={Business} alt="" />
                        </div>
                        <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                            35+ Business Partners
                        </h1>
                        <p className='text-[#8A868C] text-center text-sm roboto-font font-normal pt-2 pb-4'>
                            Explore more
                        </p>
                    </div>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#test2" className='md:col-span-1 col-span-3 bg-white shadow rounded-xl hover:scale-105 transition-all cursor-pointer'>
                    <div className='text-center px-12 pt-10'>
                        <div className='flex justify-center'>
                            <img className='w-[175px] rounded-xl' src={Cr} alt="" />
                        </div>
                        <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                            Loan Granted To Schools From Our Partners.
                        </h1>
                        <p className='text-[#8A868C] text-center text-sm roboto-font font-normal pt-2 pb-4'>
                            Explore more
                        </p>
                    </div>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#test3" className='md:col-span-1 col-span-3 bg-white shadow rounded-xl hover:scale-105 transition-all cursor-pointer'>
                    <div className='text-center px-4 pt-10'>
                        <div className='flex justify-center'>
                            <img className='w-[150px] rounded-xl' src={Fr} alt="" />
                        </div>
                        <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                            8+ Franchisees in all over india
                        </h1>
                        <p className='text-[#8A868C] text-center text-sm roboto-font font-normal pt-2 pb-4'>
                            Explore more
                        </p>
                    </div>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#test4" className='md:col-span-1 col-span-3 bg-white shadow rounded-xl hover:scale-105 transition-all cursor-pointer'>
                    <div className='text-center px-12 pt-10'>
                        <div className='flex justify-center'>
                            <img className='w-[175px] rounded-xl' src={Career} alt="" />
                        </div>
                        <h1 className='text-lg mt-3 secondary-color inter-font font-bold'>
                            40+ Career Counselor Associated With Us
                        </h1>
                        <p className='text-[#8A868C] text-center text-sm roboto-font font-normal pt-2 pb-4'>
                            Explore more
                        </p>
                    </div>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#test5" className='md:col-span-1 col-span-3 bg-white shadow rounded-xl hover:scale-105 transition-all cursor-pointer'>
                    <div className='text-center px-12 pt-8'>
                        <div className='flex justify-center'>
                            <img className='w-[175px] rounded-xl' src={Digital} alt="" />
                        </div>
                        <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                            20+ Digital Class Room Vendors
                        </h1>
                        <p className='text-[#8A868C] text-center text-sm roboto-font font-normal pt-2 pb-4'>
                            Explore more
                        </p>
                    </div>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#test6" className='md:col-span-1 col-span-3 bg-white shadow rounded-xl hover:scale-105 transition-all cursor-pointer'>
                    <div className='text-center px-12 pt-8'>
                        <div className='flex justify-center'>
                            <img className='w-[200px] rounded-xl' src={Other} alt="" />
                        </div>
                        <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                            15+ Other Services
                        </h1>
                        <p className='text-[#8A868C] text-center text-sm roboto-font font-normal pt-2 pb-4'>
                            Explore more
                        </p>
                    </div>
                </div>
              </div>
          </div>
          <div style={{backgroundImage: `url(${Screen2})`}} className='py-16 md:mx-32 mx-6 rounded-xl text-center'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl text-center secondary-color inter-font font-bold'>
                  Would you like a personal demo?
                </h1>
                <p className='text-white mt-3 px-6 md:px-0 text-sm roboto-font font-normal'>
                  Our product experts will help you understand how RTotal can help your business through a one-on-one demo session
                </p>
                <button data-bs-toggle="modal" data-bs-target="#demo" type="button" class="btn text-white bg-[#2F373D] px-8 mt-8 hover:bg-[#2f373d7a] rounded">Request a demo</button>
                <div class="modal fade" id="demo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="demoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-black fs-5" id="demoLabel">Let's Connect</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <i class="fa-solid fa-xmark text-xl"></i>
                                </button>
                            </div>
                            <div class="modal-body text-left">
                                <form className='px-4 roboto-font text-base' 
                                  action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST">
                                    <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                    <input name="Form-Type" type="hidden" id="name" value="Request a demo" />
                                    <div class="mb-2">
                                        <label for="name" class="form-label mb-1 text-black">Name</label>
                                        <input type="text" name='Name' class="form-control" id="name" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                        <input type="text" name='School-Name' class="form-control" id="sname" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                        <input type="tel" name='Mobile' class="form-control" id="tel" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1 text-black">Email address</label>
                                        <input type="email" name='Email' class="form-control" id="email" required />
                                    </div>
                                    <div className="flex gap-x-5 mt-4">
                                        <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
  
export default Collab;