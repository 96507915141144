import './landing.css'
import doodle from './doodle.png'
import doodlebg from './doodlebg.png'
import Screen2 from './doodlebg.png'
import Footer from './footer';

function Landing() {
    return (
      <div>
        <div style={{backgroundImage: `url(${doodle})`}} className="App">
          <div className='pt-28 pb-16'>
            <div style={{backgroundImage: `url(${doodlebg})`}} className='text-center py-20'>
              <h1 style={{fontFamily: 'Sedgwick Ave, cursive'}} className='md:text-5xl text-4xl text-white inter-font font-bold'>
                Journey So Far…
              </h1>
              <p className='secondary-color mt-4 mb-12 md:text-3xl text-2xl px-3 roboto-font font-base opacity-70'>
                We focalise on Education through Technology
              </p>
            </div>
            <div className='bg-white rounded-xl md:px-12 px-8 py-12 shadow -translate-y-20 md:mx-24 mx-6'>
              <h1 className='text-3xl text-center text-[#2F373D] border-b border-black/30 pb-4 inter-font font-bold'>
                About Us
              </h1>
              <p className='secondary-color lh-lg mt-4 md:text-xl text-lg roboto-font font-base opacity-70'>
                RTOTAL- School Ka Dost is India's first Al-powered school management SAAS platform, allowing schools to manage their whole operations with just a few clicks. Our Al-powered technologies assist schools in managing their data bases and leveraging cutting-edge technology to become more efficient and save fractions of money.
              </p>
            </div>
            <div class="w-full max-w-3xl mx-auto">
                    <h1 className='text-3xl mb-16 md:mr-16 secondary-color text-center inter-font font-bold'>
                      Our Journey
                    </h1>
                    <div class="-my-6 md:ml-32 ml-12">
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">Launched Our AI Powered Features</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full roboto-font">July, 2023</time>
                                <div class="text-xl font-bold primary-color roboto-font">Launched Our AI Powered Features</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">1200+ Schools On Boarded With Presence In 4 Other Countries</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full roboto-font">Dec, 2019</time>
                                <div class="text-xl pr-28 font-bold primary-color roboto-font">1200+ Schools On Boarded With Presence In 4 Other Countries</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">International Expansion Started</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full roboto-font">May, 2018</time>
                                <div class="text-xl font-bold primary-color roboto-font">International Expansion Started</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">500 Schools Onboarding</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full roboto-font">Feb, 2018</time>
                                <div class="text-xl font-bold primary-color roboto-font">500+ Schools Onboarding</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">100 Customer</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full roboto-font">April, 2017</time>
                                <div class="text-xl font-bold primary-color">100+ Customers</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">App Launched</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute roboto-font left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">Jan, 2017</time>
                                <div class="text-xl font-bold primary-color roboto-font">App Launched</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                            {/* <div class="font-caveat font-medium text-2xl primary-color inter-font mb-1 sm:mb-0">Established</div> */}
                            <div class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#169EDA] after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
                                <time class="sm:absolute roboto-font left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full">2016</time>
                                <div class="text-xl font-bold primary-color roboto-font">Established</div>
                            </div>
                            {/* <div class="text-slate-500 roboto-font">Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.</div> */}
                        </div>
                    </div>
            </div>
            <div className='md:mx-28 mx-6 mt-20'>
              <h1 className='text-3xl secondary-color text-center inter-font font-bold'>
                Core Values
              </h1>
              <p className='secondary-color lh-lg mt-4 mx-8 md:text-xl text-lg text-center md:text-left roboto-font font-base opacity-70'>
                We remain ever guided about the purpose of our existence by constantly reflecting upon our Core Values. 
                They find expression within the value system of all our Platforms, Products & People.
              </p>
              <div className='grid grid-cols-3 gap-12 my-12 justify-items-center items-center'>
                <div className="md:col-span-1 col-span-3 py-7 px-7 hover:scale-110 transition-all text-center bg-white rounded-xl shadow">
                  <i class="fa-solid fa-lightbulb animate-bounce transition-all primary-color text-5xl my-4"></i>
                  <h1 className='text-2xl secondary-color inter-font font-bold'>
                    Innovation
                  </h1>
                  <p className='secondary-color lh-base mt-3 text-xl roboto-font font-base opacity-70'>
                    We lead with cutting-edge AI technology to enhance school operations.
                  </p>
                </div>
                <div className="md:col-span-1 col-span-3 py-10 px-7 hover:scale-110 transition-all text-center bg-white rounded-xl shadow">
                  <i class="fa-solid fa-chart-simple animate-bounce transition-all primary-color text-5xl my-4"></i>
                  <h1 className='text-2xl secondary-color inter-font font-bold'>
                    Simplicity
                  </h1>
                  <p className='secondary-color lh-base mt-3 text-xl roboto-font font-base opacity-70'>
                    Our user-friendly platform streamlines tasks with ease.
                  </p>
                </div>
                <div className="md:col-span-1 col-span-3 py-10 px-7 hover:scale-110 transition-all text-center bg-white rounded-xl shadow">
                  <i class="fa-solid fa-handshake animate-bounce transition-all primary-color text-5xl my-4"></i>
                  <h1 className='text-2xl secondary-color inter-font font-bold'>
                    Affordability
                  </h1>
                  <p className='secondary-color lh-base mt-3 text-xl roboto-font font-base opacity-70'>
                    We offer cost-effective solutions for all schools in India.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{backgroundImage: `url(${Screen2})`}} className='py-16 md:mx-32 mx-6 rounded-xl text-center'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl text-center secondary-color inter-font font-bold'>
                  Would you like a personal demo?
                </h1>
                <p className='text-white mt-3 px-6 md:px-0 text-sm roboto-font font-normal'>
                  Our product experts will help you understand how RTotal can help your business through a one-on-one demo session
                </p>
                <button data-bs-toggle="modal" data-bs-target="#demo" type="button" class="btn text-white bg-[#2F373D] px-8 mt-8 hover:bg-[#2f373d7a] rounded">Request a demo</button>
                <div class="modal fade" id="demo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="demoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-black fs-5" id="demoLabel">Let's Connect</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <i class="fa-solid fa-xmark text-xl"></i>
                                </button>
                            </div>
                            <div class="modal-body text-left">
                                <form className='px-4 roboto-font text-base' 
                                  action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST">
                                    <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                    <input name="Form-Type" type="hidden" id="name" value="Request a demo" />
                                    <div class="mb-2">
                                        <label for="name" class="form-label mb-1 text-black">Name</label>
                                        <input type="text" name='Name' class="form-control" id="name" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                        <input type="text" name='School-Name' class="form-control" id="sname" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                        <input type="tel" name='Mobile' class="form-control" id="tel" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1 text-black">Email address</label>
                                        <input type="email" name='Email' class="form-control" id="email" required />
                                    </div>
                                    <div className="flex gap-x-5 mt-4">
                                        <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footer />
      </div>
    );
  }
  
export default Landing;