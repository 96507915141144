import './landing.css'
import Footer from './footer';
function Privacy() {
    return (
        <div>
<div class="container pt-36">
            <h1 className='text-3xl text-[#2F373D] inter-font font-normal'>
                Privacy Policy
            </h1>
            <p className='text-[#2F373D] leading-relaxed text-sm mt-8 roboto-font font-normal opacity-70'>
            Rubix108 takes your privacy seriously. Please read the following to learn more about how we collect, use and safeguard the information provided by you via this site. This Privacy Statement is incorporated into and subject to the Rubix108 .com Privacy Policy.
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                ACCEPTANCE OF THESE TERMS
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            By using Rubix108 applications and voluntarily providing personally identifiable information and on Rubix108 , users consent to the collection and use of such personally identifiable and information as set forth in this Privacy Policy.
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                MODIFICATION OF PRIVACY POLICY
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            Rubix108 reserves the right to modify any provision hereof from time to time, and such modification shall be effective immediately upon its posting on Rubix108.
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                INFORMATION COLLECTED
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            The first type of data we collect is aggregated, non-identifiable, non-personal data, which may process via your use of the Service (“Non-Personal Data”).
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            The second type of data is individually identifiable data, namely data that identifies an individual, or may with reasonable effort identify an individual (“Personal Data”).
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            In the event that we combine Personal Data with Non-Personal Data, we will treat the combined data as Personal Data for as long as it remains combined. Our applications will upload your profile information to <a className='primary-color' target='_blank' href = "https://www.rtotal.com/">https://www.rtotal.com/</a> database management purpose. Details of which are covered below.
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            If registration is requested, Users agree to provide accurate and complete registration information. At the time of registration Users may be required to provide on Rubix108 applications certain information which includes your name, address, phone number, and email address collectively referred as “User profile content “ User upon registration on Rubix108 , Users are assigned one or more login IDs and passwords that will enable Users to access Rubix108 . Users shall take reasonable precautions to protect against theft, loss or fraudulent use of such IDs and passwords, and Users are solely responsible for any losses arising from another party’s use of such IDs and passwords, either with or without User’s knowledge:
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font leading-relaxed text-sm font-normal opacity-70 pl-16'>
                <ul style={{listStyleType: 'disc'}}>
                    <li>Rubix108  may collect a child’s name (first name and last initial), date of birth, email address or telephone number, and the child’s parent’s email address in order to provide notice to parents that Rubix108  may contact their child for the purpose of providing the Services through Rubix108 mobile applications for which their teacher has signed up for</li>
                    <li className='py-2'>If you are a teacher, you represent and warrant that you have permission and authorization from your school to use the mobile application, you represent and warrant that you are entering into these Terms on behalf of your school and/or district.</li>
                    <li>You may be required to provide valid school credentials and a valid school email address to verify your identity in order to access and use certain features of the Services.</li>
                    <li className='py-2'>Rubix108 may collect user data from users’ contact lists for sharing the Application link via sharing feature of the App. The data collected is purely used for sharing information with users of the Application or the other parents.</li>
                    <li>Rubix108 (RTOTAL) collects/transmits/syncs/stores users <span className='font-bold'>“Contact List”</span> information to enable  <span className='font-bold'>“Share & Earn”</span> feature in the application, this feature allows users to select a “CONTACT” for your contact list store on the phone and send them the details of the organisation (school/institute) and in response earn few smiley points.</li>
                    <li className='py-2'>Rubix108 (RTOTAL) collects/transmits/syncs/stores users <span className='font-bold'>“Image”</span> information to enable <span className='font-bold'>“Profile Creation, Home-Work, Projects, School Circular & Notice, Report cards, School Activities”</span> feature allows users (school Teachers and Parents) to upload the images from their camera, Gallery and Files/Folders in the respective features and functionality of the applications. Rubix108(RTOTAL) collects the above mentioned data with prior approvals of the parents and school teachers. Rubix108(RTOTAL) stores and uses the data only for the proper functionality of the application and the respective features of the Application.</li>
                </ul>
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                Rubix108  receive and store any information you knowingly enter on the Services of Rubix108 , whether via computer, mobile phone, other wireless device, or that you provide to Rubix108  in any other way. This information may include Personal Information such as your name, phone number, email address, photograph, and, in certain circumstances, your school affiliation, which is used to provide notifications to users via the Services of Rubix108  from a teacher, school, and/or district (“alerts”), and any other information necessary for Rubix108  to provide Rubix108  services. This information is sent to RTOTAL Web Portal (<a className='primary-color' target='_blank' href = "https://www.rubixpower.com:8443/RubixTotal">https://www.rubixpower.com:8443/RubixTotal</a>). 
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            The tables below include the types of data that we collect, how it is used, and our legal basis for processing such data. 
            </p>
            <p className='text-[#2F373D] font-bold mt-8 leading-relaxed roboto-font text-sm opacity-70'>
            Data we Collect from Our End Users and Visitors: 
            </p>
            <div className="row border border-solid mt-2 ml-0">
                <div className="col-6">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Data Set 
                    </p>    
                </div>
                <div className="col-6 border-l">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Lawful Basis 
                    </p>    
                </div>
                <div className="col-6 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    When you use our App or visit our website, we (either directly or through our third-party service providers) will collect certain online identifiers such as your UDID, Android ID, and your IP address (“Online Identifiers”). 
                    </p>    
                </div>
                <div className="col-6 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We process Online Identifiers subject to (i) your consent, your consent is provided through a cookie notice on our App and website; or (ii) our legitimate interest to audit and track usage statistics and detect fraud. 
                    </p>    
                </div>
                <div className="col-6 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    As a registered user, you will be required to provide us with certain information including your full name and email address during the registration process (“ Registration Information ”).
                    <div className='mt-4'></div>
                    We will further use your email address in order to send you any necessary information related to the services (e.g., to notify you regarding any updates) and to verify your identity (“Direct Marketing”).
                    </p>    
                </div>
                <div className="col-6 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We will use the Registration Information in order to perform our contract with you, provide you with the services you requested, and assign your account.
                    <div className='mt-4'></div>
                    The process of your email for the purpose of Direct Marketing is subject to our legitimate interest.
                    </p>    
                </div>
                <div className="col-6 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    If you voluntarily contact us for support or other inquiries, you may be required to provide us with certain information such as your name and email address (“ Contact Information ”). We will use this data to provide you with the support you requested or to respond to your inquiry. We may process the contact history with you in order to improve our services, as well in order as resolve disputes (if applicable).
                    </p>    
                </div>
                <div className="col-6 border-l border-t">
                    <p className='text-[#2F373D] py-5 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We process such the Contact Information subject to our legitimate interest.
                    </p>    
                </div>
                <div className="col-6 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    In order to provide our services, you will need to share your full contact list with us.
                    </p>    
                </div>
                <div className="col-6 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We process the contact list based on you affirmative and active consent.
                    </p>    
                </div>
                <div className="col-6 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We will also collect certain technical Non-Personal Data (“Technical Information”) from you through your use of the App or when you visit our website which may include: device model, country, language, operating system, etc.
                    </p>    
                </div>
                <div className="col-6 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We will use this data to operate, provide, maintain, protect and manage our Services and the website. In addition, we will use this data under our legitimate interests to perform research, and conduct associated analytical and statistical activities, detect fraud, security or technical issues in connection with the Services on Meri Property App and the website.
                    </p>    
                </div>
            </div>
            <p className='text-[#2F373D] font-bold mt-8 leading-relaxed roboto-font text-sm opacity-70'>
            Data We Collect from Contacts:
            </p>
            <div className="row border border-solid mt-2 ml-0">
                <div className="col-6">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Data Set 
                    </p>    
                </div>
                <div className="col-6 border-l">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Lawful Basis 
                    </p>    
                </div>
                <div className="col-6 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Data Set Lawful Basis When the End User uses our services we process and retain certain data from their Contacts including: the Contact's full Name, email address, phone number that the Contact is a part of, social media links to their accounts (e.g. LinkedIn, Facebook etc.), websites that the Contact owns or operates, and any other information that the End User has saved for that particular Contact (Contact dependent). 
                    </p>    
                </div>
                <div className="col-6 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    We will use this data in order to provide our End User with the services that he or she requested. 
                    </p>    
                </div>
            </div>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                USE OF INFORMATION
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                User profile content shall be retained by Rubix108  only as custodian of such information and data. That such information and data shall only be used by specific Users and not by Rubix108
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                In order to facilitate interaction among Users of Rubix108 , other Users may share information with Users, or Users may be allowed to access certain information about other Users of Rubix108 . By entering into this Agreement, Users agree to treat information about other Users of Rubix108  strictly in accordance with the Agreement
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                Rubix108  may send push notifications to users who are students when their teacher has posted something new within the class to which they belong.
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                DATA INTEGRITY
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                We do not share, sell or rent users personally identifiable information to third parties. Rubix108  will not share or distribute any user information with third parties except as provided below
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font leading-relaxed text-sm font-normal opacity-70 pl-16'>
                <ul style={{listStyleType: 'disc'}}>
                    <li>When required to do so by law or regulation, or in response to a request from a law enforcement or governmental agency or authority or to establish or exercise our legal rights or defend against legal claims.</li>
                    <li className='py-2'>In order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Rubix 108  terms of service, or as otherwise required by law.</li>
                    <li>If Rubix 108  is acquired by or merged with another company. In this event, Rubix 108  will notify user about such merger and users being subjected to any different privacy policy</li>
                </ul>
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            Not withstanding the above, Rubix 108 employ other companies and people to perform tasks on our behalf and may need to share your information with them (and for them to use that information) in order to provide aspects of our Services to you. Some examples of services for which we may use agents include sending email or SMS, analyzing data, and providing user services. These agents may use Personal Information we share with them, for example, to assist us, to provide their services to you and/or us, and to measure and improve the performance of their services. Rubix 108 may, work with third party websites to enhance your online experience, to send messages or letting you add “apps” to your account. We have no control over the policies and practices of third party websites or services as to privacy or anything else, so if you choose to allow the automatic transmissions discussed above, please review all third party websites’ or services’ policies before disclosing any Personal Information or other content on the Website or on any third party website or service.
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            <span className='font-bold'>(i) Third Party Service Providers :</span> Our third-party service providers may integrate their code (such as: APIs, cookies, software developer kit “SDK”, etc.) within our App or website, as applicable, for the purpose of providing us with analytic, tracking and statistic reports as well as to help us protect our products’ by detecting fraudulent activity. These third parties may process certain information on you including Online Identifiers, therefore we recommend you review their privacy policy and opt-out options:
            </p>
            <div className="row border border-solid mt-2 ml-0">
                <div className="col-4">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    NAME 
                    </p>    
                </div>
                <div className="col-4 border-l">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    PURPOSE
                    </p>    
                </div>
                <div className="col-4 border-l">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    PRIVACY AND OPT OUT
                    </p>    
                </div>
                <div className="col-4 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Firebase (Google Analytic) 
                    </p>    
                </div>
                <div className="col-4 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Analytics
                    </p>    
                </div>
                <div className="col-4 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    
                    </p>    
                </div>
                <div className="col-4 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    FBSDKLoginKit SDK
                    </p>    
                </div>
                <div className="col-4 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Facebook Sign-in
                    </p>    
                </div>
                <div className="col-4 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Manage your settings through Facebook
                    </p>    
                </div>
                <div className="col-4 border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    GoogleSignIn API
                    </p>    
                </div>
                <div className="col-4 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Google Sign-in
                    </p>    
                </div>
                <div className="col-4 border-l border-t">
                    <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                    Manage your settings through Google Gmail
                    </p>    
                </div>
            </div>
            <p className='text-[#2F373D] py-2 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            We may permit third parties to use cookies or other tracking technologies to collect information about your online usage activities across our website.
            </p>    
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                Data Protection
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                Rubix108  has adopted reasonable industry standard practices to ensure that there are adequate safeguards and techniques to protect the data of the user against any unauthorized or unlawful disclosure. Rubix 108  will not store, copy, disclose or use the data of the user except as necessary for the performance by Rubix 108  of its obligations under this Agreement.
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                Rubix108  employs physical, electronic and procedural safeguards to protect the information we receive from users from unauthorized disclosure. Rubix108  limits access to personal information about user to employees of Rubix 108  who we believe reasonably need to come into contact with that information to provide services to users or in order to do their jobs. Users should not share their password with anyone. In addition, if user suspect unauthorized access to its information, it is user’s responsibility to contact Rubix108  immediately. Rubix108  will never ask user to divulge its password in written or electronic communications. Please be aware that Internet data transmission is not always secure and Rubix108  cannot warrant that information user transmit utilizing this site is secure, however, Rubix108  will always make reasonable efforts to ensure the security of the data provided on Rubix108 . However, we cannot guarantee the security of user account information. Unauthorized entry or use, hardware or software failure, and other factors may compromise the security of user information at any time.
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
            DATA RETENTION
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            We retain the data that we collect as long as it remains necessary for the purposes set forth above, all in accordance with applicable laws, or until an individual requests that we delete his or her Personal Data. We may, at our sole discretion, delete or amend information from our systems, without providing any prior notice to you, once we deem it is no longer necessary for such purposes.
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
            USER RIGHTS
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            Data protection and privacy laws provide you with the ability to exercise some rights regarding your Personal Data that we hold (depending on your jurisdiction). Please review your privacy rights you may have under the relevant data protection and privacy laws in your jurisdiction.
            </p>
            <p className='text-[#2F373D] mt-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
            To the best of Rubix108 ‘s knowledge, all material published by Rubix108 on Rubix108 is done with the full agreement of the copyright owners (be that Rubix108 or another party).
            </p>
            <p className='text-[#2F373D] mt-8 roboto-font text-base font-bold opacity-70'>
                CONTACT
            </p>
            <p className='text-[#2F373D] my-8 leading-relaxed roboto-font text-sm font-normal opacity-70'>
                Email: support@Rubix108.com
            </p>
        </div>
        <Footer />
        </div>
        
    );
  }
  
export default Privacy;

