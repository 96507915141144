import './landing.css'
import doodle from './doodle.png'
import Screen2 from './doodlebg.png'
import Footer from './footer'
import Cloud from './cloud.png'
import Airtel from './airtel.png'
import Gateway from './gateway.png'
import { useState, useEffect } from 'react'
import NTT from './ntt.png'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Pricing() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])
    return (
      <div>
            <div style={{backgroundImage: `url(${doodle})`}} className="App pb-16">
              <div className='pt-28 mx-6 mt-12 md:mt-0'>
                <h1 style={{lineHeight: '1.3'}} className='md:text-5xl text-3xl text-center primary-color inter-font font-bold'>
                  Choose Your Plan
                </h1>
                <p className='text-[#8A868C] md:mx-48 text-center md:text-xl text-base roboto-font font-normal pt-1'>
                  We Proudly Offer The <span className='font-bold'>Most Affordable</span> School Management ERP System In India, Seamlessly Integrated With Cutting-Edge AI Technology From Around The Globe.
                </p>
                <ul class="nav nav-pills mb-3 flex justify-center md:gap-8 gap-6 mt-16" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active bg-[#2F373D] text-white border border-black/30 md:px-12 px-8 roboto-font font-semibold py-3 text-lg secondary-color" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Monthly</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link md:px-10 px-8 bg-[#2F373D] text-white roboto-font font-semibold py-3 text-lg secondary-color" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Annually</button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                    <div className="grid grid-cols-3 justify-between gap-8 md:mx-20 mt-10">
                        <div className="md:col-span-1 col-span-3 bg-white rounded-2xl p-8 shadow-xl border border-black/20">
                          <p className='roboto-font font-semibold text-3xl secondary-color'>Recommended</p>
                          <span className='text-[#8A868C] text-sm roboto-font font-normal opacity-70 mt-3'>Starting From</span>
                          <h1 style={{lineHeight: '1.3'}} className='text-4xl primary-color inter-font font-bold mt-2'>
                            ₹ 9
                          </h1>
                          <p className='text-[#8A868C] text-lg roboto-font font-normal opacity-70'>
                            ( Billed Monthly )
                          </p>
                          <button type="button" class="btn btn-outline-primary mt-3 px-8" data-bs-toggle="modal" data-bs-target="#recommended">Subscribe Now</button>
                          <div class="modal fade" id="recommended" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="recommendedLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                      <div class="modal-header">
                                          <h1 class="modal-title text-black fs-5" id="recommendedLabel">Let's Connect</h1>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                          </button>
                                      </div>
                                      <div class="modal-body text-left">
                                          <form className='px-4 roboto-font text-base'
                                              action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                          >
                                              <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                              <input name="Form-Type" type="hidden" id="name" value="Recommended plan form" />
                                              <div class="mb-2">
                                                  <label for="name" class="form-label mb-1 text-black">Name</label>
                                                  <input type="text" name='Name' class="form-control" id="name" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                  <input type="text" name='School-Name' class="form-control" id="sname" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                  <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                              </div>
                                              <div class="mb-3">
                                                  <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                  <input type="email" name='Email' class="form-control" id="email" required />
                                              </div>
                                              <div className="flex gap-x-5 mt-4">
                                                  <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                  <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='border-t border-black/30 mt-10'>
                            <div className='mt-10 font-bold'>
                              <i class="fa-solid text-xl fa-check text-green-500"></i>
                              <span className='ml-3'>Communication For Lifetime (Basic)</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color primary-color"></i>
                              <span className='ml-3'>Database Organisation</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Admissions</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Communications</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Student Attendance</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Faculty Attendance</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Consent / Openion Poll</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Management Info Cards Basic</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Appointment Module</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Documents Manager</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Smart Reports</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>MIS Reports Basic</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>PSCHOOL Online Activities</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>FAQ/Training Videos</span>
                            </div>
                            <div className='mt-5'>
                              <span className='ml-3'>T&C Apply</span>
                            </div>
                          </div>
                        </div>
                        <div className="md:col-span-1 col-span-3 bg-white rounded-2xl p-8 shadow-xl border border-black/20">
                          <p className='roboto-font font-bold text-3xl secondary-color'>Premium</p>
                          <span className='text-[#8A868C] text-sm roboto-font font-normal opacity-70 mt-3'>Starting From</span>
                          <h1 style={{lineHeight: '1.3'}} className='text-4xl primary-color inter-font font-bold'>
                            ₹ 14
                          </h1>
                          <p className='text-[#8A868C] text-lg roboto-font font-normal opacity-70'>
                            ( Billed Monthly )
                          </p>
                          <button type="button" class="btn btn-outline-primary mt-3 px-8" data-bs-toggle="modal" data-bs-target="#premium">Subscribe Now</button>
                          <div class="modal fade" id="premium" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="premiumLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                      <div class="modal-header">
                                          <h1 class="modal-title text-black fs-5" id="premiumLabel">Let's Connect</h1>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                          </button>
                                      </div>
                                      <div class="modal-body text-left">
                                          <form className='px-4 roboto-font text-base'
                                              action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                          >
                                              <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                              <input name="Form-Type" type="hidden" id="name" value="Premium plan form" />
                                              <div class="mb-2">
                                                  <label for="name" class="form-label mb-1 text-black">Name</label>
                                                  <input type="text" name='Name' class="form-control" id="name" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                  <input type="text" name='School-Name' class="form-control" id="sname" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                  <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                              </div>
                                              <div class="mb-3">
                                                  <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                  <input type="email" name='Email' class="form-control" id="email" required />
                                              </div>
                                              <div className="flex gap-x-5 mt-4">
                                                  <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                  <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='border-t border-black/30 mt-10'>
                            <div className='mt-10 font-bold'>
                              <i class="fa-solid text-xl fa-check text-green-500"></i>
                              <span className='ml-3'>Includes All Of Recommended</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Instant Communication Reports</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Fees Management and Custom Receipt</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Online Payment Gateway </span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Demand Notes</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Report Card (CBSE and STATE Boards)</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Bulk Results Publish on App</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Class / Unit Test Marks</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>HR Basic</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Greate Book (School Dairy)</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Transport Fees</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Enquiry / Leads Management</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Visitors Management </span>
                            </div>
                            <div className='mt-5'>
                              <span className='ml-3'>T&C Apply</span>
                            </div>
                          </div>
                        </div>
                        <div className="md:col-span-1 col-span-3 bg-white rounded-2xl p-8 shadow-xl border border-black/20">
                          <p className='roboto-font font-bold text-3xl secondary-color'>Enterprise</p>
                          <span className='text-[#8A868C] text-sm roboto-font font-normal opacity-70 mt-3'>Starting From</span>
                          <h1 style={{lineHeight: '1.3'}} className='text-4xl primary-color inter-font font-bold'>
                            ₹ 348
                          </h1>
                          <p className='text-[#8A868C] text-lg roboto-font font-normal opacity-70'>
                            ( Billed Monthly )
                          </p>
                          <button type="button" class="btn btn-outline-primary mt-3 px-8" data-bs-toggle="modal" data-bs-target="#enterprise">Subscribe Now</button>
                          <div class="modal fade" id="enterprise" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="enterpriseLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                      <div class="modal-header">
                                          <h1 class="modal-title text-black fs-5" id="enterpriseLabel">Let's Connect</h1>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                          </button>
                                      </div>
                                      <div class="modal-body text-left">
                                          <form className='px-4 roboto-font text-base'
                                              action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                          >
                                              <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                              <input name="Form-Type" type="hidden" id="name" value="Enterprise plan form" />
                                              <div class="mb-2">
                                                  <label for="name" class="form-label mb-1 text-black">Name</label>
                                                  <input type="text" name='Name' class="form-control" id="name" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                  <input type="text" name='School-Name' class="form-control" id="sname" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                  <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                              </div>
                                              <div class="mb-3">
                                                  <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                  <input type="email" name='Email' class="form-control" id="email" required />
                                              </div>
                                              <div className="flex gap-x-5 mt-4">
                                                  <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                  <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='border-t border-black/30 mt-10'>
                            <div className='mt-10 font-bold'>
                              <i class="fa-solid text-xl fa-check text-green-500"></i>
                              <span className='ml-3'>Includes All Of Premium</span>
                            </div>  
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>HR and Payroll</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Library</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>LMS</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Transport Fees GPS Tracking</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Inventory</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Hostel</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Expense Management</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>RFID Intregration</span>
                            </div>
                            <div className='mt-5'>
                              <span className='ml-3'>T&C Apply</span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                    <h1 style={{fontFamily: 'Sedgwick Ave, cursive'}} className='text-center mt-16 font-semibold text-3xl secondary-color w-full'><span className='primary-color'>Get 10% off</span> when you pay annually</h1>
                  <div className="grid grid-cols-3 justify-between gap-8 md:mx-20 mt-16">
                        <div className="md:col-span-1 col-span-3 bg-white rounded-2xl p-8 shadow-xl border border-black/20">
                          <p className='roboto-font font-semibold text-3xl secondary-color'>Recommended</p>
                          <span className='text-[#8A868C] text-sm roboto-font font-normal opacity-70 mt-3'>Starting From</span>
                          <h1 style={{lineHeight: '1.3'}} className='text-4xl primary-color inter-font font-bold mt-2'>
                            <span className='color3'>₹ </span><strike className='color3 text-3xl'>108</strike> 97
                          </h1>
                          <p className='text-[#8A868C] text-lg roboto-font font-normal opacity-70'>
                            ( Billed Annually )
                          </p>
                          <button type="button" class="btn btn-outline-primary mt-3 px-8" data-bs-toggle="modal" data-bs-target="#recommendedP">Subscribe Now</button>
                          <div class="modal fade" id="recommendedP" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="recommendedPLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                      <div class="modal-header">
                                          <h1 class="modal-title text-black fs-5" id="recommendedPLabel">Let's Connect</h1>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                          </button>
                                      </div>
                                      <div class="modal-body text-left">
                                          <form className='px-4 roboto-font text-base'
                                              action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                          >
                                              <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                              <input name="Form-Type" type="hidden" id="name" value="Recommended plan form" />
                                              <div class="mb-2">
                                                  <label for="name" class="form-label mb-1 text-black">Name</label>
                                                  <input type="text" name='Name' class="form-control" id="name" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                  <input type="text" name='School-Name' class="form-control" id="sname" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                  <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                              </div>
                                              <div class="mb-3">
                                                  <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                  <input type="email" name='Email' class="form-control" id="email" required />
                                              </div>
                                              <div className="flex gap-x-5 mt-4">
                                                  <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                  <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='border-t border-black/30 mt-10'>
                            <div className='mt-10 font-bold'>
                              <i class="fa-solid text-xl fa-check text-green-500"></i>
                              <span className='ml-3'>Communication For Lifetime (Basic)</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color primary-color"></i>
                              <span className='ml-3'>Database Organisation</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Admissions</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Communications</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Student Attendance</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Faculty Attendance</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Consent / Openion Poll</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Management Info Cards Basic</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Appointment Module</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Documents Manager</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Smart Reports</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>MIS Reports Basic</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>PSCHOOL Online Activities</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>FAQ/Training Videos</span>
                            </div>
                            <div className='mt-5'>
                              <span className='ml-3'>T&C Apply</span>
                            </div>
                          </div>
                        </div>
                        <div className="md:col-span-1 col-span-3 bg-white rounded-2xl p-8 shadow-xl border border-black/20">
                          <p className='roboto-font font-bold text-3xl secondary-color'>Premium</p>
                          <span className='text-[#8A868C] text-sm roboto-font font-normal opacity-70 mt-3'>Starting From</span>
                          <h1 style={{lineHeight: '1.3'}} className='text-4xl primary-color inter-font font-bold'>
                            <span className='color3'>₹ </span><strike className='color3 text-3xl'>168</strike> 151
                          </h1>
                          <p className='text-[#8A868C] text-lg roboto-font font-normal opacity-70'>
                            ( Billed Annually )
                          </p>
                          <button type="button" class="btn btn-outline-primary mt-3 px-8" data-bs-toggle="modal" data-bs-target="#premiumP">Subscribe Now</button>
                          <div class="modal fade" id="premiumP" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="premiumPLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                      <div class="modal-header">
                                          <h1 class="modal-title text-black fs-5" id="premiumPLabel">Let's Connect</h1>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                          </button>
                                      </div>
                                      <div class="modal-body text-left">
                                          <form className='px-4 roboto-font text-base'
                                              action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                          >
                                              <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                              <input name="Form-Type" type="hidden" id="name" value="Premium plan form" />
                                              <div class="mb-2">
                                                  <label for="name" class="form-label mb-1 text-black">Name</label>
                                                  <input type="text" name='Name' class="form-control" id="name" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                  <input type="text" name='School-Name' class="form-control" id="sname" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                  <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                              </div>
                                              <div class="mb-3">
                                                  <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                  <input type="email" name='Email' class="form-control" id="email" required />
                                              </div>
                                              <div className="flex gap-x-5 mt-4">
                                                  <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                  <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='border-t border-black/30 mt-10'>
                            <div className='mt-10 font-bold'>
                              <i class="fa-solid text-xl fa-check text-green-500"></i>
                              <span className='ml-3'>Includes All Of Recommended</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Instant Communication Reports</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Fees Management and Custom Receipt</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Online Payment Gateway </span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Demand Notes</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Report Card (CBSE and STATE Boards)</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Bulk Results Publish on App</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Class / Unit Test Marks</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>HR Basic</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Greate Book (School Dairy)</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Transport Fees</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Enquiry / Leads Management</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Visitors Management </span>
                            </div>
                            <div className='mt-5'>
                              <span className='ml-3'>T&C Apply</span>
                            </div>
                          </div>
                        </div>
                        <div className="md:col-span-1 col-span-3 bg-white rounded-2xl p-8 shadow-xl border border-black/20">
                          <p className='roboto-font font-bold text-3xl secondary-color'>Enterprise</p>
                          <span className='text-[#8A868C] text-sm roboto-font font-normal opacity-70 mt-3'>Starting From</span>
                          <h1 style={{lineHeight: '1.3'}} className='text-4xl primary-color inter-font font-bold'>
                            <span className='color3'>₹ </span><strike className='color3 text-3xl'>4176</strike> 3758
                          </h1>
                          <p className='text-[#8A868C] text-lg roboto-font font-normal opacity-70'>
                            ( Billed Annually )
                          </p>
                          <button type="button" class="btn btn-outline-primary mt-3 px-8" data-bs-toggle="modal" data-bs-target="#enterpriseP">Subscribe Now</button>
                          <div class="modal fade" id="enterpriseP" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="enterprisePLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                  <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                      <div class="modal-header">
                                          <h1 class="modal-title text-black fs-5" id="enterprisePLabel">Let's Connect</h1>
                                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                          </button>
                                      </div>
                                      <div class="modal-body text-left">
                                          <form className='px-4 roboto-font text-base'
                                              action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                          >
                                              <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                              <input name="Form-Type" type="hidden" id="name" value="Enterprise plan form" />
                                              <div class="mb-2">
                                                  <label for="name" class="form-label mb-1 text-black">Name</label>
                                                  <input type="text" name='Name' class="form-control" id="name" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                  <input type="text" name='School-Name' class="form-control" id="sname" required />
                                              </div>
                                              <div class="mb-2">
                                                  <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                  <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                              </div>
                                              <div class="mb-3">
                                                  <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                  <input type="email" name='Email' class="form-control" id="email" required />
                                              </div>
                                              <div className="flex gap-x-5 mt-4">
                                                  <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                  <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='border-t border-black/30 mt-10'>
                            <div className='mt-10 font-bold'>
                              <i class="fa-solid text-xl fa-check text-green-500"></i>
                              <span className='ml-3'>Includes All Of Premium</span>
                            </div>  
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>HR and Payroll</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Library</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>LMS</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Transport Fees GPS Tracking</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Inventory</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Hostel</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>Expense Management</span>
                            </div>
                            <div className='mt-3'>
                              <i class="fa-solid fa-check primary-color"></i>
                              <span className='ml-3'>RFID Intregration</span>
                            </div>
                            <div className='mt-5'>
                              <span className='ml-3'>T&C Apply</span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='md:mx-16 mx-6'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl mt-16 text-center secondary-color inter-font font-bold'>
                  Choose Your <br /> Add-On's
                </h1>
              <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={screenSize.width < 800 ? 1 : 5}
                  autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                  }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                  className='px-4 py-5 cursor-grab'
                  >
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-8 px-12 shadow">
                      <div className='text-center'>
                        <i class="fa-regular text-6xl fa-message primary-color"></i>
                      </div>
                      <h1 style={{lineHeight: '1.3'}} className='text-xl mt-4 text-center secondary-color inter-font font-bold'>
                        SMS
                      </h1>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-8 px-12 shadow">
                      <div className='text-center'>
                        <i class="fa-solid text-6xl fa-globe primary-color"></i>
                      </div>
                      <h1 style={{lineHeight: '1.3'}} className='text-xl mt-4 text-center secondary-color inter-font font-bold'>
                        Website
                      </h1>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-8 px-12 shadow">
                      <div className='text-center'>
                        <i class="fa-solid text-6xl fa-fingerprint primary-color"></i>
                      </div>
                      <h1 style={{lineHeight: '1.3'}} className='text-xl mt-4 text-center secondary-color inter-font font-bold'>
                        Biometric
                      </h1>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-8 px-12 shadow">
                      <div className='text-center'>
                        <i class="fa-regular text-6xl fa-id-card primary-color"></i>
                      </div>
                      <h1 style={{lineHeight: '1.3'}} className='text-xl mt-4 text-center secondary-color inter-font font-bold'>
                        I - Card
                      </h1>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-8 px-12 shadow">
                      <div className='text-center'>
                        <i class="fa-solid text-6xl fa-location-dot primary-color"></i>
                      </div>
                      <h1 style={{lineHeight: '1.3'}} className='text-xl mt-4 text-center secondary-color inter-font font-bold'>
                        VTS
                      </h1>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-8 px-12 shadow">
                      <div className='text-center'>
                        <i class="fa-solid text-6xl fa-hand-holding-dollar primary-color"></i>
                      </div>
                      <h1 style={{lineHeight: '1.3'}} className='text-xl mt-4 text-center secondary-color inter-font font-bold'>
                        Loans
                      </h1>
                    </div>
                  </SwiperSlide>
                </Swiper>
            </div>
            <div className='md:mx-24 mx-6'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl mt-16 text-center secondary-color inter-font font-bold'>
                  Integration
                </h1>
              <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={screenSize.width < 800 ? 1 : 4}
                  autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                  }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                  className='px-4 py-5 cursor-grab grid grid-cols-4'
                  >
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-16 px-12 shadow">
                      <img className='' src={Airtel} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center hover:scale-110 transition-all bg-white rounded-xl py-16 px-8 shadow">
                      <img className='py-1' src={NTT} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center text-center hover:scale-110 transition-all bg-white rounded-xl py-2 px-12 shadow">
                      <img className='' src={Cloud} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col justify-center text-center hover:scale-110 transition-all bg-white rounded-xl py-2 px-12 shadow">
                      <img className='w-56' src={Gateway} />
                    </div>
                  </SwiperSlide>
                </Swiper>
            </div>
            <div style={{backgroundImage: `url(${Screen2})`}} className='py-16 mt-20 md:mx-32 mx-6 rounded-xl text-center'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl text-center secondary-color inter-font font-bold'>
                  Would you like a personal demo?
                </h1>
                <p className='text-white mt-3 px-6 md:px-0 text-sm roboto-font font-normal'>
                  Our product experts will help you understand how RTotal can help your business through a one-on-one demo session
                </p>
                <button data-bs-toggle="modal" data-bs-target="#demo" type="button" class="btn text-white bg-[#2F373D] px-8 mt-8 hover:bg-[#2f373d7a] rounded">Request a demo</button>
                <div class="modal fade" id="demo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="demoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-black fs-5" id="demoLabel">Let's Connect</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <i class="fa-solid fa-xmark text-xl"></i>
                                </button>
                            </div>
                            <div class="modal-body text-left">
                                <form className='px-4 roboto-font text-base' 
                                  action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST">
                                    <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                    <input name="Form-Type" type="hidden" id="name" value="Request a demo" />
                                    <div class="mb-2">
                                        <label for="name" class="form-label mb-1 text-black">Name</label>
                                        <input type="text" name='Name' class="form-control" id="name" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                        <input type="text" name='School-Name' class="form-control" id="sname" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                        <input type="tel" name='Mobile' class="form-control" id="tel" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1 text-black">Email address</label>
                                        <input type="email" name='Email' class="form-control" id="email" required />
                                    </div>
                                    <div className="flex gap-x-5 mt-4">
                                        <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
  
export default Pricing;