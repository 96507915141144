import './landing.css'
import { useState, useEffect } from 'react'
import doodle from './doodle.png'
import youstory from './youstory.png'
import screen1 from './screen1.jpg'
import screen2 from './doodlebg.png'
import profile from './profile.png'
import education from './1300+.png'
import ai from './ai.png'
import { motion } from 'framer-motion'
import {fadeIn} from '../pages/variants'
import Play from './play.png'
import Award from './award.png'
import Hand from './hand.png'
import Footer from './footer'
import Test1 from './test1.jpg'
import Test2 from './test2.jpg'
import Test3 from './test3.jpg'
import Test4 from './test4.jpg'
import Test5 from './test5.jpg'
import Test6 from './test6.jpeg'
import Testlogo1 from './testlogo1.jpg'
import Testlogo2 from './testlogo2.jpg'
import Testlogo3 from './testlogo3.jpg'
import Testlogo4 from './testlogo4.png'
import Testlogo5 from './testlogo5.png'
import Inc42 from './42.png'
import Alert from './alert'
import { Link } from 'react-router-dom'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


function Landing() {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])

    let url = window.location.href;
    let isForm = false
    if(url.includes('true')){
        isForm = true
        console.log(isForm);
    }else{
        isForm = false
        console.log(isForm);
    }
    return (
        <div>
        <div style={{backgroundImage: `url(${doodle})`}} className="App md:h-screen">
        {isForm ? <Alert /> : ''}
          <div className="md:grid md:grid-cols-4 flex flex-col-reverse pt-40 mx-6 lg:mx-20 justify-items-stretch">
            <div 
                
                className="md:col-span-2 col-span-4 justify-self-start">
                <div>
                    <h1 style={{lineHeight: '1.3'}} className='lg:text-5xl text-4xl primary-color inter-font font-bold lg:pr-32'>
                        India's Leading AI Powered School ERP Software
                    </h1>
                    <p className='color3 mt-4 roboto-font font-normal md:pr-24 opacity-70'>
                        Elevate your school management to a whole new level with India's 1st AI Powered School ERP Software. 
                        Seamlessly streamline operations, harness cutting-edge technology, and empower your institution for the future. 
                        Discover a smarter way to manage education. 
                    </p>
                </div>
                <div className='bg-[#169EDA] rounded hover:bg-[#169fdad5] w-40 text-center text-white mt-4'>
                    <button style={{outline: 'none', backgroundColor: 'transparent'}} type="button" class="btn text-white form-control" data-bs-toggle="modal" data-bs-target="#playstore">
                        <i class="fa-solid fa-play"></i>
                        <span className='ml-3 font roboto-font text-sm'>Play Store</span>
                    </button>
                    <div class="modal fade" id="playstore" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="playstoreLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title text-black fs-5" id="playstoreLabel">Let's Connect</h1>
                                    <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fa-solid fa-xmark text-xl"></i>
                                    </button>
                                </div>
                                <div class="modal-body text-left">
                                    <form className='px-4 roboto-font text-base'
                                        action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                    >
                                        <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                        <input name="Form-Type" type="hidden" id="name" value="Playstore form" />
                                        <div class="mb-2">
                                            <label for="name" class="form-label mb-1 text-black">Name</label>
                                            <input type="text" name='Name' class="form-control" id="name" required />
                                        </div>
                                        <div class="mb-2">
                                            <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                            <input type="text" name='School-Name' class="form-control" id="sname" required />
                                        </div>
                                        <div class="mb-2">
                                            <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                            <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                        </div>
                                        <div class="mb-3">
                                            <label for="email" class="form-label mb-1 text-black">Email address</label>
                                            <input type="email" name='Email' class="form-control" id="email" required />
                                        </div>
                                        <div className="flex gap-x-5 mt-4">
                                            <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                            <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-[#2F373D] hover:bg-[#2f373dc0] rounded w-44 text-center text-white mt-3'>
                <button style={{backgroundColor: 'transparent'}} type="button" class="btn text-white form-control focus-ring focus-ring-dark" data-bs-toggle="modal" data-bs-target="#download">
                    <i class="fa-solid fa-download"></i>
                    <span className='ml-3 font roboto-font text-sm'>Download Now</span>
                </button>
                <div class="modal fade" id="download" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-black fs-5" id="downloadLabel">Let's Connect</h1>
                                <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa-solid fa-xmark text-xl"></i>
                                </button>
                            </div>
                            <div class="modal-body text-left">
                                <form className='px-4 roboto-font text-base' 
                                    action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                >
                                    <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                    <input name="Form-Type" type="hidden" id="name" value="App Download form" />
                                    <div class="mb-2">
                                        <label for="name" class="form-label mb-1 text-black">Name</label>
                                        <input type="text" name='Name' class="form-control" id="name" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                        <input type="text" name='School-Name' class="form-control" id="sname" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                        <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1 text-black">Email address</label>
                                        <input type="email" name='Email' class="form-control" id="email" required />
                                    </div>
                                    <div className="flex gap-x-5 mt-4">
                                        <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div 
                
                className="lg:col-span-2 col-span-4 justify-self-end">
              <img className='w-[700px] rounded-xl' src={screen1} alt="" />
            </div>
          </div>
        </div>
        <div style={{backgroundImage: `url(${screen2})`}} className="App lg:h-screen">
          <div className="grid grid-cols-4 mt-12 md:mx-16 mx-6 justify-items-stretch items-center md:pt-32 pt-20">
                <div 
                    
                    className="lg:col-span-2 col-span-4 lg:justify-self-center justify-self-center">
                    <iframe 
                        src="https://www.youtube.com/embed/ITerZGycCCg?si=6jUZUiEtubZdCSKV" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen className='lg:w-[500px] lg:h-[350px] w-[310px] h-[200px] rounded-2xl'>
                    </iframe>
                </div>
                <div
                    
                    className="lg:col-span-2 col-span-4 my-10 lg:my-0 lg:justify-self-start justify-self-center lg:ml-24">
                    <h1 style={{lineHeight: '1.3'}} className='md:text-3xl text-3xl text-white inter-font font-bold'>
                        Take advantage of your 19-minute slot for a complimentary Product Demo!
                    </h1>
                    <p className='text-white mt-3 text-sm roboto-font font-normal md:pr-32 opacity-70'>
                        Experience the Future of School Management! 🚀
                        Rtotal - School ka dost is India's Leading AI-based School ERP system that will revolutionize your school management. 
                    </p>
                    <p className='text-white mt-3 text-sm roboto-font font-normal md:pr-32 opacity-70'>
                        Say goodbye to endless paperwork and hello to automation in just 19 minutes! Don't miss out on the opportunity to transform your school. Start your 14-day trial now and discover the power of Rtotal today!
                    </p>
                    <div className='bg-white hover:bg-[#ffffff88] rounded text-center primary-color mt-4'>
                        <button type="button" className="btn border-none py-3 form-control hover:bg-[#2F373D] secondary-color hover:text-white" data-bs-toggle="modal" data-bs-target="#trial">
                            <span style={{fontFamily: 'Sedgwick Ave, cursive'}} className='font roboto-font text-xl'>Start 14 Days trial</span>
                        </button>
                        <div class="modal fade" id="trial" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="trialLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title text-black fs-5" id="trialLabel">Let's Connect</h1>
                                        <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="fa-solid fa-xmark text-xl"></i>
                                        </button>
                                    </div>
                                    <div class="modal-body text-left">
                                        <form 
                                            className='px-4 roboto-font text-base'
                                            id="contactform" 
                                            action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                                        >
                                            <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                            <input name="Form-Type" type="hidden" id="name" value="14 days trial form" />
                                            <div class="mb-2">
                                                <label for="name" class="form-label mb-1 text-black">Name</label>
                                                <input type="text" name='Name' class="form-control" id="name" required />
                                            </div>
                                            <div class="mb-2">
                                                <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                                <input type="text" name='School-Name' class="form-control" id="sname" required />
                                            </div>
                                            <div class="mb-2">
                                                <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                                <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                            </div>
                                            <div class="mb-3">
                                                <label for="email" class="form-label mb-1 text-black">Email address</label>
                                                <input type="email" name='Email' class="form-control" id="email" required />
                                            </div>
                                            <div className="flex gap-x-5 mt-4">
                                                <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                                <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{backgroundImage: `url(${doodle})`}} className="App lg:h-screen">
          <div 
            >
            <h1 style={{lineHeight: '1.3', fontFamily: 'Sedgwick Ave, cursive'}} className='text-7xl text-center pt-12 secondary-color'>
                Why Us
            </h1>
            <div className='mt-4 md:mx-20 mx-6'>
            <div class="modal fade" id="why1" tabindex="-1" aria-labelledby="why1Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="why1Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={youstory} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="why2" tabindex="-1" aria-labelledby="why2Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="why2Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={youstory} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="why3" tabindex="-1" aria-labelledby="why3Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="why3Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={youstory} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="why4" tabindex="-1" aria-labelledby="why4Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="why4Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={youstory} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="why5" tabindex="-1" aria-labelledby="why5Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="why5Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={youstory} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="why6" tabindex="-1" aria-labelledby="why6Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="why6Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={youstory} />
                        </div>
                    </div> 
                </div>
            </div>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={screenSize.width < 800 ? 1 : 3}
                    autoplay={{
                        delay: 9000,
                        disableOnInteraction: false,
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    className='md:px-4 py-5 cursor-grab'
                    >
                    <SwiperSlide>
                        <div className='min-w-[280px] h-[330px] bg-white shadow rounded-xl hover:scale-105 transition-all'>
                            <div className='text-center px-4 md:pt-4 '>
                                <div>
                                    <img className='w-[230px] md:ml-9 ml-3 rounded-xl' src={Inc42} alt="" />
                                </div>
                                <h1 className='text-lg md:mt-2 secondary-color inter-font font-bold'>
                                    1 Million Dollar Funding Raised
                                </h1>
                                <a target='_blank' href='https://inc42.com/buzz/rubix108-technologies-edtech-funding/' className='cursor-pointer hover:font-bold hover:scale-105 transition-all text-[#8A868C] text-center text-sm roboto-font font-normal pt-1'>
                                    Explore more
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='min-w-[280px] h-[330px] bg-white shadow rounded-xl hover:scale-105 transition-all'>
                            <div className='text-center px-4 pt-4'>
                                <div>
                                    <img className='w-[230px] md:ml-10 ml-6 rounded-xl' src={education} alt="" />
                                </div>
                                <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                                    1300+ Educational Institutes
                                </h1>
                                <p data-bs-toggle="modal" data-bs-target="#why2" className='text-[#8A868C] cursor-pointer hover:scale-105 transition-all hover:font-bold text-center text-sm roboto-font font-normal pt-1'>
                                    Explore more
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='min-w-[280px] h-[330px] bg-white shadow rounded-xl hover:scale-105 transition-all'>
                            <div className='text-center flex flex-col justify-center px-4 pt-4'>
                                <div className=''>
                                    <img className='w-[230px] ml-10 rounded-xl' src={Play} alt="" />
                                </div>
                                <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                                    More Than 2L+ Downloads
                                </h1>
                                <a href='https://play.google.com/store/apps/details?id=com.testapp.android.activity&hl=en&gl=US' target='_blank' className='text-[#8A868C] cursor-pointer hover:scale-105 transition-all hover:font-bold text-center text-sm roboto-font font-normal pt-1'>
                                    Explore more
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='min-w-[280px] h-[330px] bg-white shadow rounded-xl hover:scale-105 transition-all'>
                            <div className='text-center px-4 pt-4'>
                                <div>
                                    <img className='w-[290px] rounded-xl' src={Award} alt="" />
                                </div>
                                <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                                    Awarded Best School ERP 2022
                                </h1>
                                <p data-bs-toggle="modal" data-bs-target="#why4" className='text-[#8A868C] cursor-pointer hover:scale-105 transition-all hover:font-bold text-center text-sm roboto-font font-normal pt-1'>
                                    Explore more
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='min-w-[280px] h-[330px] bg-white shadow rounded-xl hover:scale-105 transition-all'>
                            <div className='text-center px-4 md:pt-4'>
                                <div className='w-[230px]'>
                                    <img className='w-full md:ml-10 ml-6 rounded-xl' src={Hand} alt="" />
                                </div>
                                <h1 className='text-lg md:mt-2 secondary-color inter-font font-bold'>
                                    11+ Educational Service Partners 
                                </h1>
                                <Link to='/collab' className='text-[#8A868C] cursor-pointer hover:scale-105 transition-all hover:font-bold text-center text-sm roboto-font font-normal pt-1'>
                                    Explore more
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='min-w-[280px] h-[330px] bg-white shadow rounded-xl hover:scale-105 transition-all'>
                            <div className='text-center px-4 pt-4'>
                                <div>
                                    <img className='w-[230px] md:ml-10 ml-6 rounded-xl' src={ai} alt="" />
                                </div>
                                <h1 className='text-lg mt-2 secondary-color inter-font font-bold'>
                                    56+ AI Powered Features
                                </h1>
                                <p data-bs-toggle="modal" data-bs-target="#why6" className='text-[#8A868C] cursor-pointer hover:scale-105 transition-all hover:font-bold text-center text-sm roboto-font font-normal pt-1'>
                                    Explore more
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
          </div>
        </div>
        <div style={{backgroundImage: `url(${screen2})`}} className="App md:h-screen">
          <div className='md:pt-24 py-16 md:py-0 md:mx-20 mx-6'>
            <h1 style={{lineHeight: '1.3'}} className='md:text-4xl text-3xl md:w-[500px] text-white inter-font font-bold md:pr-32'>
              We Have Powerful User Experience
            </h1>
            <div class="modal fade" id="test1" tabindex="-1" aria-labelledby="test1Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="test1Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={Test1} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test2" tabindex="-1" aria-labelledby="test2Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="test2Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={Test2} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test3" tabindex="-1" aria-labelledby="test3Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="test3Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={Test3} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test4" tabindex="-1" aria-labelledby="test4Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="test4Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={Test4} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test5" tabindex="-1" aria-labelledby="test5Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="test5Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={Test5} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="test6" tabindex="-1" aria-labelledby="test6Label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="test6Label">Testimonial</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body flex justify-center py-10">
                            <img className='rounded-xl w-96' src={Test6} />
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
            <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={screenSize.width < 800 ? 1 : 3}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    className='md:px-4 md:py-5 cursor-grab'
                    >
                    <SwiperSlide>
                        <div className='w-[300px] h-[260px] bg-white shadow mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 hover:scale-105 transition-all'>
                            <img className='w-[50px]' src={Testlogo1} alt="" />
                            <div>
                                <p className='text-[#8A868C] mt-6 text-sm roboto-font font-normal opacity-70'>
                                    This is to state that our teachers are using the Rubix Portal as per the directives given by our instructor. It is further to state that because...
                                </p>
                            </div>
                            <div className='mt-6 flex justify-between items-center'>
                                <div>
                                    <p className='roboto-font font-bold text-sm'>Pradeep Handa</p>
                                    <p className='text-[#8A868C] text-xs roboto-font font-normal opacity-70'>
                                        Principal
                                    </p>
                                </div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#test1" className='text-sm roboto-font font-normal hover:text-[#2F373D] transition-all hover:animate-pulse text-[#8A868C] cursor-pointer'>
                                    Learn more
                                    <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[300px] h-[260px] bg-white shadow mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 hover:scale-105 transition-all'>
                            <img className='w-[50px]' src={Testlogo2} alt="" />
                            <div>
                                <p className='text-[#8A868C] mt-6 text-sm roboto-font font-normal opacity-70'>
                                    "Rubix apps" integrated School Management app has been implemented in our school in July 2016. We are working on six modules of "Rubix apps"...
                                </p>
                            </div>
                            <div className='mt-6 flex justify-between items-center'>
                                <div>
                                    <p className='roboto-font font-bold text-sm'>Vijay Govind Desh...</p>
                                    <p className='text-[#8A868C] text-xs roboto-font font-normal opacity-70'>
                                        Principal
                                    </p>
                                </div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#test2" className='text-sm roboto-font font-normal hover:text-[#2F373D] transition-all hover:animate-pulse text-[#8A868C] cursor-pointer'>
                                    Learn more
                                    <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[300px] h-[260px] bg-white shadow mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 hover:scale-105 transition-all'>
                            <img className='w-[50px]' src={Testlogo3} alt="" />
                            <div>
                                <p className='text-[#8A868C] mt-6 text-sm roboto-font font-normal opacity-70'>
                                    Since its introduction in July 2016, the Rubix app has been a seamless bridge between our school and parents. We're grateful for the ongoing training and...
                                </p>
                            </div>
                            <div className='mt-6 flex justify-between items-center'>
                                <div>
                                    <p className='roboto-font font-bold text-sm'>Vaishali Rathod</p>
                                    <p className='text-[#8A868C] text-xs roboto-font font-normal opacity-70'>
                                        Principal
                                    </p>
                                </div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#test3" className='text-sm roboto-font font-normal hover:text-[#2F373D] transition-all hover:animate-pulse text-[#8A868C] cursor-pointer'>
                                    Learn more
                                    <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <div className='w-[300px] h-[260px] bg-white shadow mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 hover:scale-105 transition-all'>
                            <img className='w-[50px]' src={profile} alt="" />
                            <div>
                                <p className='text-[#8A868C] mt-6 text-sm roboto-font font-normal opacity-70'>
                                    Kings & Queens International School, Padum Nagar, Charoda, is a user of RUBIX 108 application for Teachers, parents & students. The app is so...
                                </p>
                            </div>
                            <div className='mt-6 flex justify-between items-center'>
                                <div>
                                    <p className='roboto-font font-bold text-sm'>Mr. V. Bala Subr...</p>
                                    <p className='text-[#8A868C] text-xs roboto-font font-normal opacity-70'>
                                        Principal
                                    </p>
                                </div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#test4" className='text-sm roboto-font font-normal hover:text-[#2F373D] transition-all hover:animate-pulse text-[#8A868C] cursor-pointer'>
                                    Learn more
                                    <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <div className='w-[300px] h-[260px] bg-white shadow mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 hover:scale-105 transition-all'>
                            <img className='w-[50px]' src={Testlogo4} alt="" />
                            <div>
                                <p className='text-[#8A868C] mt-6 text-sm roboto-font font-normal opacity-70'>
                                    We're grateful to the Rubix108 team for their valuable application. Their flexibility and dedication have streamlined our operations, improving productivity...
                                </p>
                            </div>
                            <div className='mt-6 flex justify-between items-center'>
                                <div>
                                    <p className='roboto-font font-bold text-sm'>Mr. Anurag Diwan</p>
                                    <p className='text-[#8A868C] text-xs roboto-font font-normal opacity-70'>
                                        Principal
                                    </p>
                                </div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#test5" className='text-sm roboto-font font-normal hover:text-[#2F373D] transition-all hover:animate-pulse text-[#8A868C] cursor-pointer'>
                                    Learn more
                                    <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[300px] h-[260px] bg-white shadow mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 hover:scale-105 transition-all'>
                            <img className='w-[50px]' src={Testlogo5} alt="" />
                            <div>
                                <p className='text-[#8A868C] mt-6 text-sm roboto-font font-normal opacity-70'>
                                    We feel highly pleased to thank Rubix 108 Technologies for their commitment and support right from kick off meeting to present. The ERP software...
                                </p>
                            </div>
                            <div className='mt-6 flex justify-between items-center'>
                                <div>
                                    <p className='roboto-font font-bold text-sm'>Mrs. Vinita Ashir...</p>
                                    <p className='text-[#8A868C] text-xs roboto-font font-normal opacity-70'>
                                        Principal
                                    </p>
                                </div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#test6" className='text-sm roboto-font font-normal hover:text-[#2F373D] transition-all hover:animate-pulse text-[#8A868C] cursor-pointer'>
                                    Learn more
                                    <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
            </Swiper>
            </div>
          </div>
        </div>
        <div style={{backgroundImage: `url(${doodle})`}} className="App">
          <div className='text-center mx-6 flex flex-col items-center'>
            <h1 style={{lineHeight: '1.3'}} className='md:text-4xl text-3xl pt-12 text-center text-[#2F373D] inter-font font-bold'>
              Some Excellent features for you
            </h1>
          </div>
          <div className='md:ml-20 mx-6 flex flex-col lg:flex-row justify-center items-center md:gap-8 gap-6'>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                  <i class="fa-solid fa-chalkboard-user"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Student Information Management</p>
                </div>
            </div>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                  <i class="fa-solid fa-building-columns"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Academic Management</p>
                </div>
            </div>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                  <i class="fa-solid fa-people-roof"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Teacher and Staff Management</p>
                </div>
            </div>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                  <i class="fa-solid fa-people-group"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Communication and Collaboration</p>
                </div>
            </div>
          </div>
          <div className='md:ml-20 mx-6 mt-3 flex flex-col lg:flex-row justify-center items-center md:gap-8 gap-6'>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                  <i class="fa-solid fa-coins"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Finance and Fee Management</p>
                </div>
            </div>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                    <i class="fa-solid fa-book"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Library and Resource Management</p>
                </div>
            </div>
            <div className='w-[200px] h-[180px] hover:scale-105 transition-all bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-6 pl-6 flex flex-col text-center'>
                <div className='flex justify-center mt-2'>
                  <div className='w-[50px] h-[50px] rounded-xl bg-[#169EDA] text-white flex justify-center items-center'>
                  <i class="fa-solid fa-computer"></i>
                  </div>
                </div>
                <div className='mt-6'>
                  <p className='roboto-font font-bold text-base'>Online Learning and E-Learning</p>
                </div>
            </div>
            <div className='w-[200px] h-[180px] bg-white shadow-lg mt-12 rounded-xl pr-5 pb-5 pt-3 pl-6 flex flex-col text-center'>
              <h1 className='text-xl text-center text-[#2F373D] inter-font font-bold'>
                56+ Features You Will Get With Us
              </h1>
              <button data-bs-toggle="modal" data-bs-target="#explore" className='bg-[#2F373D] rounded pl-5 pr-5 py-2 w-36 text-center text-white mt-3'>
                <span className='font roboto-font text-sm'>Explore Now</span>
              </button>
            <div class="modal fade" id="explore" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exploreLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title text-black fs-5" id="exploreLabel">Let's Connect</h1>
                            <button type="button" class="btn-close secondary-color" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>
                        <div class="modal-body text-left">
                            <form 
                                className='px-4 roboto-font text-base'
                                id="contactform" 
                                action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST"
                            >
                                <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                <input name="Form-Type" type="hidden" id="name" value="Explore 56" />
                                <div class="mb-2">
                                    <label for="name" class="form-label mb-1 text-black">Name</label>
                                    <input type="text" name='Name' class="form-control" id="name" required />
                                </div>
                                <div class="mb-2">
                                    <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                    <input type="text" name='School-Name' class="form-control" id="sname" required />
                                </div>
                                <div class="mb-2">
                                    <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                    <input type="tel" minlength='10' maxlength='10' name='Mobile' class="form-control" id="tel" required />
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label mb-1 text-black">Email address</label>
                                    <input type="email" name='Email' class="form-control" id="email" required />
                                </div>
                                <div className="flex gap-x-5 mt-4">
                                    <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div style={{backgroundImage: `url(${screen2})`}} className='py-16 mt-20 md:mx-32 mx-6 rounded-xl text-center'>
                <h1 style={{lineHeight: '1.3'}} className='text-3xl text-center secondary-color inter-font font-bold'>
                  Would you like a personal demo?
                </h1>
                <p className='text-white mt-3 px-6 md:px-0 text-sm roboto-font font-normal'>
                  Our product experts will help you understand how RTotal can help your business through a one-on-one demo session
                </p>
                <button data-bs-toggle="modal" data-bs-target="#demo" type="button" class="btn text-white bg-[#2F373D] px-8 mt-8 hover:bg-[#2f373d7a] rounded">Request a demo</button>
                <div class="modal fade" id="demo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="demoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div style={{backgroundImage: `url(${doodle})`, backgroundSize: '100%'}} class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title text-black fs-5" id="demoLabel">Let's Connect</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <i class="fa-solid fa-xmark text-xl"></i>
                                </button>
                            </div>
                            <div class="modal-body text-left">
                                <form className='px-4 roboto-font text-base' 
                                  action="https://formsubmit.io/send/4e4c3264-e8ae-4d50-855c-6bd5a1a36f1a" method="POST">
                                    <input name="_redirect" type="hidden" id="name" value="https://www.rtotal.com/?q=true" />
                                    <input name="Form-Type" type="hidden" id="name" value="Request a demo" />
                                    <div class="mb-2">
                                        <label for="name" class="form-label mb-1 text-black">Name</label>
                                        <input type="text" name='Name' class="form-control" id="name" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="sname" class="form-label mb-1 text-black">School Name</label>
                                        <input type="text" name='School-Name' class="form-control" id="sname" required />
                                    </div>
                                    <div class="mb-2">
                                        <label for="tel" class="form-label mb-1 text-black">Mobile</label>
                                        <input type="tel" name='Mobile' class="form-control" id="tel" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label mb-1 text-black">Email address</label>
                                        <input type="email" name='Email' class="form-control" id="email" required />
                                    </div>
                                    <div className="flex gap-x-5 mt-4">
                                        <button type="button" class="btn text-white bg-[#2F373D] hover:bg-[#2f373d7a] rounded border form-control border-black/30" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn form-control text-white bg-[#169EDA] hover:bg-[#169fda86] rounded">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
    );
  }
  
export default Landing;