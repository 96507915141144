
import './landing.css'
function Alert() {
    return (
        <div class="alert z-50 text-center absolute form-control alert-success opacity-100 alert-dismissible fade show" role="alert">
            Thanks for contacting us! We'll get in touch with you ASAP.
            <button type="button" class="btn-close pt-3" data-bs-dismiss="alert" aria-label="Close">
                <i class="fa-solid fa-xmark text-xl"></i>
            </button>
        </div>
    );
  }
  
export default Alert;

